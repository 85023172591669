'use client'

import Cookies from 'js-cookie'
import noop from 'lodash/noop'
import { useRouter, usePathname, useParams } from 'next/navigation'
import { ReactNode, createContext } from 'react'

import i18n, { defaultLocale, Locale } from 'core/i18n'

export const I18nContext = createContext({
  locale: i18n[defaultLocale],
  fallbackLocale: i18n[defaultLocale].data,
  locales: i18n,
  select: (_: Locale) => noop(),
})

export default function I18nProvider({ data, children }: { data: any; children: ReactNode }): JSX.Element {
  const { push } = useRouter()
  const { locale } = useParams() || {}
  const pathname = usePathname()

  const localeData: I18n.Keys = {
    ...(data as I18n.Keys),
    ...i18n[locale as Locale].data,
  }

  const regex = new RegExp(`^(\/((${Object.keys(i18n).join('|')})(?:\/|$)?)?)`)

  const context = {
    locales: i18n,
    fallbackLocale: i18n['en'].data,
    locale: { ...i18n[locale as Locale], data: localeData },
    select: async (lngCode: Locale) => {
      Cookies.set('NEXT_LOCALE', lngCode, { path: '/' })

      if (pathname) {
        push(pathname.replace(regex, `/${lngCode}/`))
      }
    },
  }

  return <I18nContext.Provider value={context}>{children}</I18nContext.Provider>
}
