import de from '../locales/de.json'
import en from '../locales/en.json'
import es from '../locales/es.json'
import fr from '../locales/fr.json'
import nl from '../locales/nl.json'

export type Locale = 'en' | 'nl' | 'de' | 'fr' | 'es'
export const defaultLocale: Locale = 'en'

export type LocaleType = {
  label: string
  data?: I18n.Keys
  code: Locale
  loaded?: boolean
}

type defaultLocaleType = {
  default: { label: string; data: I18n.Keys; code: 'default' }
}

type Locales = {
  [key in Locale]: LocaleType
}

const i18n: defaultLocaleType & Locales = {
  default: { label: 'English', data: en, code: 'default' },
  en: { label: 'English', data: en, code: 'en' },
  nl: {
    label: 'Nederlands',
    code: 'nl',
    data: nl as typeof en,
  },
  de: {
    label: 'Deutsch',
    code: 'de',
    data: de as typeof en,
  },
  fr: {
    label: 'Francais',
    code: 'fr',
    data: fr as typeof en,
  },
  es: {
    label: 'Español',
    code: 'es',
    data: es as typeof en,
  },
}

export default i18n
