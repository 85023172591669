'use client'

import { useContext } from 'react'

import { I18nContext } from 'core/context/I18nProvider'
import type { LocaleType, Locale } from 'core/i18n'

export default function useI18n(): {
  t: typeof t
  locale: LocaleType
  select: (_: Locale) => void
} {
  const { locale, fallbackLocale, select } = useContext(I18nContext)

  function t(key: keyof I18n.Keys, defaultT?: string): string {
    const text: string | null = locale.data?.[key] || fallbackLocale?.[key] || defaultT || null

    if (!text) {
      return ''
    }

    return text || ''
  }

  return {
    t,
    locale,
    select,
  }
}
